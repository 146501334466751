<div class="flex gap-2 justify-start items-center px-6">
    <div>
        <div class="flex h-10 w-10 flex-0 items-center justify-center rounded-full sm:mr-4 bg-red-100 text-red-600 dark:bg-red-600 dark:text-red-50">
            <mat-icon class="text-current" [svgIcon]="'mat_outline:warning_amber'"></mat-icon>
        </div>
    </div>
    <div class="flex items-center h-full" style="height: 65px !important">
        <p mat-dialog-title class="text-xl text-red-600 font-bold m-0 p-0 h-full">Session Timeout Warning</p>
    </div>
</div>
<mat-dialog-content class="py-2">
    <p>Your session is about to expire due to inactivity.</p>
    <p>Time remaining: <strong>{{ countdown }} seconds</strong></p>
</mat-dialog-content>
<mat-dialog-actions class="px-6">
    <button mat-stroked-button color="primary" (click)="resetSession()">
        <mat-icon svgIcon="mat_outline:restart_alt"></mat-icon>
        Continue Session
    </button>
</mat-dialog-actions>
