import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-session-timeout-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
    templateUrl: './session-timeout-dialog.component.html',
    styleUrl: './session-timeout-dialog.component.scss'
})
export class SessionTimeoutDialogComponent {
    countdown = 30; // Countdown starts at 30 seconds
    private interval: any;

    constructor(
        public dialogRef: MatDialogRef<SessionTimeoutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.startCountdown();
    }

    private startCountdown(): void {
        this.interval = setInterval(() => {
            this.countdown--;
            if (this.countdown === 0) {
                this.logout();
            }
        }, 1000);
    }

    resetSession(): void {
        clearInterval(this.interval);
        this.dialogRef.close('reset');
    }

    logout(): void {
        clearInterval(this.interval);
        this.dialogRef.close('logout');
    }

    ngOnDestroy(): void {
        clearInterval(this.interval); // Clean up the interval when the dialog is destroyed
    }
}
