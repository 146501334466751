<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div
            class="mb-4 mt-5 flex h-16 flex-0 items-center justify-center pl-2 pr-6"
        >
            <img class="max-w-36" src="images/logo/CPL-logo-full.png" />
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div
            class="mb-4 mt-2 flex h-16 flex-0 items-center justify-center pl-2 pr-6 opacity-12"
        >
            <img class="max-w-36" src="images/logo/logo-text-on-dark.svg" />
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left-right'"
                ></mat-icon>
            </button> -->

            <!-- <button
                class="-ml-4"
                mat-raised-button
                (click)="onLogout()">
                <mat-icon
                    [svgIcon]="'mat_outline:logout'"
                ></mat-icon>
                Logout
            </button> -->

            <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <img src="/images/avatars/user.jpg" class="rounded-full w-8" alt="">
            </button>
            <mat-menu #menu="matMenu">
                <div class="flex flex-col gap-2 py-4 px-6">
                    <p class="font-bold">Signed In as</p>
                    <p>{{name}}</p>
                    <p>{{phone}}</p>
                    <p class="role-badge" [ngClass]="role">{{role}}</p>
                </div>
                <button
                    class="ml-6"
                    mat-stroked-button
                    color="primary"
                    (click)="onLogout()">
                    <mat-icon
                        [svgIcon]="'mat_outline:logout'"
                    ></mat-icon>
                    Logout
                </button>
            </mat-menu>

        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
