import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { SessionTimeoutDialogComponent } from 'shared/components/dialog/session-timeout-dialog/session-timeout-dialog.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimer: any;
  private readonly TIMEOUT = 30 * 60 * 1000; // 30 minutes
  private readonly WARNING_TIME = 30 * 1000; // 30 seconds
  private routerSubscription!: Subscription;

  constructor(private router: Router, private ngZone: NgZone, private dialog: MatDialog) {
    this.initializeRouteListener();
    this.initializeActivityListeners();
  }

  private initializeRouteListener(): void {
    // Listen for route changes to disable the timer on specific routes
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url === '/sign-in') {
          this.clearTimer();
        } else {
          this.resetTimer();
        }
      });
  }

  private initializeActivityListeners(): void {
    const activityEvents = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];
    activityEvents.forEach((event) =>
      window.addEventListener(event, () => this.resetTimer())
    );
  }

  private resetTimer(): void {
    if (this.router.url === '/sign-in') {
      this.clearTimer();
      return;
    }

    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }

    // Start the inactivity timer
    this.inactivityTimer = setTimeout(() => {
      this.showTimeoutWarning();
    }, this.TIMEOUT);
  }

  private clearTimer(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = null;
    }
  }

  private showTimeoutWarning(): void {
    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(SessionTimeoutDialogComponent, {
        disableClose: true,
        data: {},
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'reset') {
          this.resetTimer();
        } else {
          sessionStorage.removeItem('accessToken');
          this.router.navigate(['/sign-in']);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.clearTimer();
  }
}
