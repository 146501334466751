import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { sidebarNavigation } from 'shared/data/data';
import { MatMenuModule } from '@angular/material/menu';
import { roles } from 'shared/data/data';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        MatIconModule,
        MatButtonModule,
        FuseFullscreenComponent,
        RouterOutlet,
        RouterLink,
        MatMenuModule,
        CommonModule
    ],
    providers: [
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService
    ]
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation | FuseNavigationItem[];
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    name: string = '';
    phone: string = '';
    role: string = '';

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _jwtHelper: JwtHelperService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         // console.log('Navigation:', navigation);
        //         // this.navigation = navigation;

        //         const token = sessionStorage.getItem('accessToken'); // Get the JWT token from local storage
        //         const decodedToken = this._jwtHelper.decodeToken(token); // Decode the token
        //         const userRoleId = decodedToken.roleId; // Extract the roleId from the token

        //         this.navigation = navigation.default;

        //         // Filter the default navigation based on the user's roleId
        //         this.navigation = this.filterNavigationByRole(this.navigation, userRoleId);
        //     });
        const token = sessionStorage.getItem('accessToken'); // Get the JWT token from local storage
        const decodedToken = this._jwtHelper.decodeToken(token); // Decode the token
        const userRoleId = decodedToken.roleId; // Extract the roleId from the token
        this.name = decodedToken.firstName + (decodedToken.lastName ? ' ' + decodedToken.lastName : '');
        this.phone = decodedToken.mobile;
        this.role = this.getRoleNameById(decodedToken.roleId);
        this.navigation = this.filterNavigationByRole(sidebarNavigation, userRoleId);


        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    filterNavigationByRole(navigation: FuseNavigationItem[], roleId: string): FuseNavigationItem[] {
        return navigation.filter(item => {
            if (item.roles && !item.roles.includes(roleId)) {
                return false;
            }
            if (item.children) {
                item.children = this.filterNavigationByRole(item.children, roleId);
            }
            return true;
        });
    }

    onLogout(){
        sessionStorage.removeItem('accessToken');
        this._router.navigate(['/sign-in']);
    }

    getRoleNameById(roleId: string): string {
        return roles.find(role => role._id === roleId).name;
    }


}
