import { Route } from '@angular/router';
// import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { roles, rolesMap } from 'shared/data/data';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'inventory' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
                canActivate: [NoAuthGuard],
            },
        ],
    },
    {
        path: 'inventory',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('app/modules/admin/inventory/ecommerce.routes'),
        data: {
            expectedRole: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk] // Admin Role, SubAdmin, Frontdesk
        }
    },
    {
        path: 'users',
        component: LayoutComponent,
        loadChildren: () => import('app/modules/admin/users/users.routes'),
        data: {
            expectedRole: [rolesMap.admin] // Admin Role
        },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        path: 'booking',
        component: LayoutComponent,
        loadChildren: () => import('app/modules/admin/booking/booking.routes'),
        data: {
            expectedRole: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk] // Admin Role Subadmin Frontdesk
        },
        canActivate: [AuthGuard],
    },
    {
        path: '',
        component: LayoutComponent,
        loadChildren: () => import('app/modules/admin/data-management/data-management.routes'),
        data: {
            expectedRole: [rolesMap.admin, rolesMap.subAdmin] // Admin Role Subadmin Frontdesk
        },
        canActivate: [AuthGuard],
    },
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     loadChildren: () => import('app/modules/admin/analysis/analysis.routes'),
    //     data: {
    //         expectedRole: [rolesMap.admin, rolesMap.subAdmin] // Admin Role Subadmin
    //     }
    // },
    // 404 & Catch all
    {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
            import(
                'app/modules/error-404/error-404.routes'
            ),
    },
    { path: '**', redirectTo: '404-not-found' },
];
