<div class="relative flex h-full w-full flex-col">
    <!-- Dismiss button -->
    @if (data.dismissible) {
        <div class="absolute right-0 top-0 pr-4 pt-4">
            <button mat-icon-button [matDialogClose]="undefined">
                <mat-icon
                    class="text-secondary"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>
    }

    <!-- Content -->
    <div
        class="flex flex-auto flex-col items-center p-8 pb-6 sm:flex-row sm:items-start sm:pb-8"
    >
        <!-- Icon -->
        @if (data.iconImage.show) {
            <!-- <div
                class="flex h-10 w-10 flex-0 items-center justify-center rounded-full sm:mr-4"
                [ngClass]="{
                    'bg-primary-100 text-primary-600 dark:bg-primary-600 dark:text-primary-50':
                        data.icon.color === 'primary',
                    'bg-accent-100 text-accent-600 dark:bg-accent-600 dark:text-accent-50':
                        data.icon.color === 'accent',
                    'bg-warn-100 text-warn-600 dark:bg-warn-600 dark:text-warn-50':
                        data.icon.color === 'warn',
                    'bg-gray-100 text-gray-600 dark:bg-gray-600 dark:text-gray-50':
                        data.icon.color === 'basic',
                    'bg-blue-100 text-blue-600 dark:bg-blue-600 dark:text-blue-50':
                        data.icon.color === 'info',
                    'bg-green-100 text-green-500 dark:bg-green-500 dark:text-green-50':
                        data.icon.color === 'success',
                    'bg-amber-100 text-amber-500 dark:bg-amber-500 dark:text-amber-50':
                        data.icon.color === 'warning',
                    'bg-red-100 text-red-600 dark:bg-red-600 dark:text-red-50':
                        data.icon.color === 'error',
                }"
            > -->
            <div
                class="flex h-10 w-10 flex-0 items-center justify-center rounded-full sm:mr-4"
                [ngStyle]="{ 'background-color': data.iconImage.iconBackgroundColor }"
            >
                <!-- <mat-icon
                    class="text-current"
                    [svgIcon]="data.icon.name"
                ></mat-icon> -->
                <div class="min-w-10 min-h-7 h-7 px-2 leading-6 flex items-center justify-center">
                    <img [src]="data.iconImage.iconUrl" class="max-w-5" alt="">
                </div>
            </div>
        }

        <div
            class="mt-4 flex flex-col items-center space-y-1 text-center sm:mt-0 sm:items-start sm:pr-8 sm:text-left w-full"
        >
            <!-- Title -->
            @if (data.title) {
                <div
                    class="text-xl font-medium leading-6"
                    [innerHTML]="data.title"
                ></div>
            }

            <!-- Message -->
            @if (data.message) {
                <div
                    class="text-secondary"
                    [innerHTML]="data.message"
                ></div>
            }

            <form [formGroup]="bookingStatusForm" class="w-full pt-4">
                @if (data.type === 'allocate') {
                    <div>
                        <mat-form-field class="w-full">
                            <mat-label>Allocate Collection Partner</mat-label>
                            <mat-select [formControlName]="'allocateTo'">
                                <div class="px-5 py-2 relative">
                                    <label for="searchcollectionPartner">Search Collection Partner</label>
                                    <input matInput id="searchcollectionPartner" placeholder="Search"
                                        (input)="filterUsers($event)"
                                        class="py-5 px-3"
                                        style="border: 1px solid lightgray; border-radius: 5px;" />
                                </div>
                                <ng-container *ngFor="let user of filteredUsersList; ">
                                    <mat-option class="my-2" [value]="user._id">{{user.firstName + (user.lastName ? ' ' + user.lastName : '')}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                }

                @if(data.type === 'reschedule'){
                    <div class="flex flex-col">
                        <mat-form-field class="w-full">
                            <mat-label>Sample Collection Date</mat-label>
                            <input matInput [matDatepicker]="picker" [formControlName]="'bookingDate'" [min]="getMinDate()">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            @if (bookingStatusForm.get('bookingDate').hasError('required')) {
                                <mat-error> Sample Collection Date is required </mat-error>
                            }
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>Sample Collection Time</mat-label>
                            <mat-select id="collectionTime" [formControlName]="'timeSlot'">
                                <mat-option *ngFor="let slot of getFilteredSlotList()"
                                    [value]="slot.value">{{slot.name}}</mat-option>
                            </mat-select>
                            @if (bookingStatusForm.get('timeSlot').hasError('required')) {
                                <mat-error> Sample Collection Time is required </mat-error>
                            }
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>Allocate Collection Partner</mat-label>
                            <mat-select [formControlName]="'allocateTo'">
                                <div class="px-5 py-2 relative">
                                    <label for="searchcollectionPartner">Search Collection Partner</label>
                                    <input matInput id="searchcollectionPartner" placeholder="Search"
                                        (input)="filterUsers($event)"
                                        class="py-5 px-3"
                                        style="border: 1px solid lightgray; border-radius: 5px;" />
                                </div>
                                <ng-container *ngFor="let user of filteredUsersList; ">
                                    <mat-option class="my-2" [value]="user._id">{{user.firstName + (user.lastName ? ' ' + user.lastName : '')}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                }
                <div>
                    <mat-form-field class="w-full">
                        <mat-label>Comments</mat-label>
                        <textarea matInput
                                  [formControlName]="'comments'"
                                  cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1"
                                  cdkAutosizeMaxRows="5"></textarea>
                    </mat-form-field>
                </div>
                @if (data.type === 'file-upload') {
                    <div *ngIf="fileUrl" class="flex flex-col w-full">
                        <div class="flex gap-2 w-full">
                            <div class="">
                                <button mat-raised-button class="banner-search-btn min-w-fit" (click)="openFile()">
                                    <mat-icon svgIcon="mat_outline:visibility"></mat-icon>
                                    View Report
                                </button>
                            </div>
                            <div class="flex flex-col">
                                <button mat-raised-button (click)="openFilePicker()" class="banner-search-btn min-w-fit">
                                    <mat-icon svgIcon="mat_outline:add"></mat-icon>
                                    Add Report
                                </button>
                                <span class="text-slate-400 ml-4">{{ uploadedFileInfo }}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!fileUrl" class="flex flex-col w-full">
                        <button mat-raised-button (click)="openFilePicker()" class="banner-search-btn min-w-fit">
                            <mat-icon svgIcon="mat_outline:add"></mat-icon>
                            Add Document
                        </button>
                        <span class="text-slate-400 ml-4">{{ uploadedFileInfo }}</span>
                    </div>
                }
            </form>
        </div>
    </div>

    <!-- Actions -->
    @if (data.actions.confirm.show || data.actions.cancel.show) {
        <div
            class="flex items-center justify-center space-x-3 bg-gray-50 px-6 py-4 dark:bg-black dark:bg-opacity-10 sm:justify-end"
        >
            <!-- Cancel -->
            @if (data.actions.cancel.show) {
                <button mat-stroked-button (click)="onCancelClick()">
                    {{ data.actions.cancel.label }}
                </button>
            }

            <!-- Confirm -->
            @if (data.actions.confirm.show) {
                <button
                    mat-flat-button
                    [ngStyle]="{ 'background-color': data.actions.confirm.backgroundColor, 'color': data.actions.confirm.color }"
                    (click)="onSaveClick()"
                    [disabled]="!bookingStatusForm.valid"
                >
                    {{ data.actions.confirm.label }}
                </button>
            }
        </div>
    }
</div>
