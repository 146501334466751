import { FuseNavigationItem } from "@fuse/components/navigation";

export const rolesMap = {
    admin: "66fbb51140efed7cfaf3b396",
    subAdmin: "67174b61fa21d8bb5b5e739c",
    frontDesk: "66fbb54940efed7cfaf3b39a",
    collectionPartner: "66fbb55b40efed7cfaf3b39c",
    customer: "66fbb52e40efed7cfaf3b398"
};

export const sidebarNavigation: FuseNavigationItem[] = [
    {
        id: 'users',
        subtitle: 'User Management',
        type: 'group',
        icon: '',
        children: [
            {
                id: 'users.view',
                title: 'View Users',
                type: 'basic',
                icon: 'mat_outline:people',
                link: '/users/list',
                roles: [rolesMap.admin]
            },
            {
                id: 'users.add',
                title: 'Add User',
                type: 'basic',
                icon: 'mat_outline:person_add',
                link: '/users/detail/0',
                roles: [rolesMap.admin]
            }
        ],
        roles: [rolesMap.admin]
    },
    {
        id      : 'apps',
        title   : '',
        subtitle: 'Manage Tests & Packages',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id      : 'apps.inventory',
                title   : 'Tests & Packages',
                type    : 'collapsable',
                icon    : 'mat_outline:medical_services',
                children: [
                    {
                        id   : 'apps.inventory.view',
                        title: 'View All',
                        type : 'basic',
                        link : '/inventory/list',
                        roles: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk]
                    },
                    {
                        id   : 'apps.inventory.addTest',
                        title: 'Add New Test',
                        type : 'basic',
                        link : '/inventory/detail/0/test',
                        roles: [rolesMap.admin, rolesMap.subAdmin]
                    },
                    {
                        id   : 'apps.inventory.addPackage',
                        title: 'Add New Package',
                        type : 'basic',
                        link : '/inventory/detail/0/package',
                        roles: [rolesMap.admin, rolesMap.subAdmin]
                    },
                ],
            },
        ],
        roles: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk]
    },
    {
        id: 'booking',
        subtitle: 'Booking Management',
        type: 'group',
        icon: '',
        children: [
            {
                id: 'booking.view',
                title: 'View Bookings',
                type: 'basic',
                icon: 'mat_outline:pending_actions',
                link: '/booking/list',
                roles: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk]
            },
            {
                id: 'booking.add',
                title: 'Create Booking',
                type: 'basic',
                icon: 'mat_outline:add_task',
                link: '/booking/detail/0',
                roles: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk]
            }
        ],
        roles: [rolesMap.admin, rolesMap.subAdmin, rolesMap.frontDesk]
    },
    {
        id: 'data-management',
        subtitle: 'Data Management',
        type: 'group',
        icon: '',
        children: [
            {
                id: 'data.mandatory-docs',
                title: 'Mandatory Documents',
                type: 'basic',
                icon: 'heroicons_outline:document-check',
                link: '/mandatory-docs',
                roles: [rolesMap.admin, rolesMap.subAdmin]
            },
            // {
            //     id: 'booking.add',
            //     title: 'Create Booking',
            //     type: 'basic',
            //     icon: 'mat_outline:add_task',
            //     link: '/booking/detail/0',
            //     roles: [rolesMap.admin, rolesMap.subAdmin]
            // }
        ],
        roles: [rolesMap.admin, rolesMap.subAdmin]
    },
    // {
    //     id: 'analysis',
    //     subtitle: 'Analysis',
    //     type: 'group',
    //     icon: '',
    //     children: [
    //         {
    //             id: 'analysis-feedback',
    //             title: 'Feedback',
    //             type: 'basic',
    //             icon: 'mat_outline:feedback',
    //             link: '/feedback',
    //             roles: [rolesMap.admin, rolesMap.subAdmin]
    //         },
    //     ],
    //     roles: [rolesMap.admin, rolesMap.subAdmin]
    // },
];

export const testCategories = [
    {
        id:1,
        name: 'Full Body',
    },
    {
        id:2,
        name: 'Fever',
    },
    {
        id:3,
        name: 'Heart',
    },
    {
        id:4,
        name: 'Vitamin',
    },
    {
        id:5,
        name: 'Diabetes',
    },
    {
        id:6,
        name: 'Thyroid',
    },
    {
        id:7,
        name: 'Cancer',
    },
    {
        id:8,
        name: 'Pregnancy',
    },
    {
        id:9,
        name: 'Lifestyle',
    },
    {
        id:10,
        name: 'Arthritis',
    },
    {
        id:11,
        name: 'Brain',
    },
    {
        id:12,
        name: 'Liver',
    },
    {
        id:13,
        name: 'Kidney',
    },
    {
        id:14,
        name: 'Others',
    },
]

export const slotList: {name: string, value: number}[] = [
    {value : 5, name: '5 am - 6 am'},
    {value : 6, name: '6 am - 7 am'},
    {value : 7, name: '7 am - 8 am'},
    {value : 8, name: '8 am - 9 am'},
    {value : 9, name: '9 am - 10 am'},
    {value : 10, name: '10 am - 11 am'},
    {value : 11, name: '11 am - 12 pm'},
    {value : 12, name: '12 pm - 1 pm'},
    {value : 13, name: '1 pm - 2 pm'},
    {value : 14, name: '2 pm - 3 pm'},
    {value : 15, name: '3 pm - 4 pm'},
    {value : 16, name: '4 pm - 5 pm'},
    {value : 17, name: '5 pm - 6 pm'},
    {value : 18, name: '6 pm - 7 pm'},
    {value : 19, name: '7 pm - 8 pm'},
    // {value : 20, name: '8 pm - 9 pm'},
]

export const roles = [
    { _id: "66fbb51140efed7cfaf3b396", name: "Admin" },
    { _id: "67174b61fa21d8bb5b5e739c", name: "Sub Admin" },
    { _id: "66fbb54940efed7cfaf3b39a", name: "Front Desk" },
    { _id: "66fbb55b40efed7cfaf3b39c", name: "Collection Partner" },
    { _id: "66fbb52e40efed7cfaf3b398", name: "Customer" },
];



